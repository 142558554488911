@import './shared/styles/_vuetify-overrides.scss';
























































.cassie-tabs {
  box-shadow: var(--global-cassie-tabs-container-box-shadow);
}

.cassie-tab {
  border-right: var(--global-cassie-tab-border-right);
  &:nth-last-child(1) {
    border-right: none;
  }
}
