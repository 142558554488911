@import './shared/styles/_vuetify-overrides.scss';














































































































































































































































































































.cassie-data-subject-expansion-panels {
	.v-expansion-panel-header {
		min-height: 32px !important;
		padding: 12px !important;
		position: relative;
	}
	.v-expansion-panel-content {
		padding: 0 !important;
	}
	.v-expansion-panel-content__wrap {
		padding: 16px !important;
	}
	.subgroup-name {
		width: 17%;
		display: inline-block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.radio-buttons-wrapper.fixed-radio-buttons {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: left 0.3s ease;
		&.radio-buttons-create {
			left: calc(17% + 26px);
		}
		&.radio-buttons-edit {
			left: calc(27% + 22px);
		}
	}

	.expansion-panel-header {
		.v-label {
			color: #fff;
		}
	}
}
